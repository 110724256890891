export const SEMESTERS = [
	{
		label: "First",
		value: "First"
	},
	{
		label: "Second",
		value: "Second"
	}
];

export const SCHOOL_DETAILS = {
	name: "Chukwuemeka Odumegwu Ojukwu University",
	location: "Uli",
	pmb: "P.M.B. 02, Uli"
};

export const PAYMENTTYPES = [
	{
		label: "Full Payment",
		value: "Full"
	},
	{
		label: "First Installment",
		value: "FirstInstallment"
	},
	{
		label: "Second Installment",
		value: "SecondInstallment"
	}
];

export const PAYMENTIDENTIFIER = {
	schoolFees: 1,
	acceptance: 5,
	hostelArrears: 16,
	hostel: "HostelFees",
	sundry: 9,
	changeOfDepartment: "ChangeOfDepartment"
};

export const PAGESIZE = {
	sm: 5,
	md: 10,
	lg: 15,
	xl: 20,
	xxl: 30
};

export const SEARCH_DELAY = {
	sm: 300,
	md: 500,
	lg: 800,
	xl: 1000
};

export const INITIAL_DATE = "0001-01-01T00:00:00";

export const MINIMUM_AGE = 15;
export const MAXIMUM_AGE = 80;
export const TENECE_SUPPORT_URL = "https://teneceschoolsupport.com/";
export const TRANSCRIPT_APP_URL = "http://coouportal.teneceschoolsupport.com/modules/transcript/landing.aspx";

// Authentication Constants
export const TOKEN_HOLDER = "coouToken";
export const USER_ROLE_HOLDER = "coouUserRole";
export const USER_CONDITION_HOLDER = "coouUserCondition";
export const USER_NAME_HOLDER = "coouUserName";
export const BIRTHDAY_STATE_HOLDER = "coouBirthday";
export const STUDENT_TYPE_HOLDER = "coouStudentType";
export const USER_TYPES = [
	"student",
	"lecturer",
	"super admin",
	"dean of faculty",
	"head of part time",
	"faculty officer",
	"class adviser",
	"admissions",
	"customer care",
	"admin",
	"pg bursar",
	"student affairs",
	"pg admission officer",
	"admin part time",
	"exams_records",
	"jupeb admin",
	"bursar",
	"super admin",
	"on-site support",
	"head of department",
	"ict admin",
	"ict head",
	"course adviser"
];

export const STUDENT_TYPES = {
	UNDERGRADUATE: 1,
	JUPEB: 8,
	Diploma: 3,
	POSTGRADUATE: 2,
	Sandwich: 9,
	"Sandwich Diploma": 10,
	INTER_UNIVERSITY_TRANSFER: 2
};
